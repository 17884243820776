import Vue from 'vue'
import Vuex from 'vuex'
import { auth, firestoreInstance, firestoreCollections, firebaseConfig } from '../firebase';
import axios from 'axios';
import Algolia from 'algoliasearch';
import Firebase from 'firebase/app';
import batchifyArray from '../utils/batchify';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import compileTimeline from '../utils/compile-timeline';
dayjs.extend(utc);
dayjs.extend(tz);

//const firebaseFunctions = functions();
const db = firestoreCollections();
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    students: {},
    scheduleDays: {},
    studentScheduleDays: {},
    isFetchingMonthlyScheduleDays: false,
    algolia: null,
    searchQuery: { filter: 'all', text: '' },
    searchPageNumber: 0,
    searchTotalFound: 0,
    searchResult: null,
    isWritingScheduleDay: false,
    isLoadingSearchResults: false,
    firestoreUnsubscribeFunctions: [],
    scheduleDiagramContent: null,
    groups: {},
    isFetchingGroups: false
  },
  getters: {
    students: function (state) {
      return state.students;
    },
    student: (state) => (id) => {
      return state.students[id];
    },
    scheduleDays: (state) => (id) => {
      return state.scheduleDays[id];
    },
    searchQuery: (state) => state.searchQuery,
    searchPageNumber: (state) => state.searchPageNumber,
    searchFoundStudents: (state) => {
      if (!state.searchResult) {
        return [];
      }
      return state.searchResult.hits.map(hit => { 
        return state.students[hit.objectID];
      });
    },
    isFetchingGroups: (state) => state.isFetchingGroups,
    searchFoundStudentIds: (state) => {
      if (!state.searchResult) {
        return [];
      }
      return state.searchResult.hits.map(hit => { 
        return hit.objectID;
      });
    },
    searchTotalFound: (state) => state.searchTotalFound,
    studentNumberOfPages: (state) => {
      if (!state.searchResult) {
        return 0;
      } else {
        return state.nbPages;
      }
    },
    group: (state) => (id) => state.groups[id],
    groupByName: (state) => (name) => {
      const ids = Object.keys(state.groups);
      for (let i = 0; i < ids.length; i++) {
        if (state.groups[ids[i]].name === name) {
          return state.groups[ids[i]];
        }
      }
      return null;
    },
    groupSelectorList: (state) => {
      return Object.keys(state.groups).map((id) => {
        return {
          text: state.groups[id].name,
          value: id
        };
      });
    },
    isLoadingSearchResults: (state) => state.isLoadingSearchResults,
    isWritingScheduleDay: (state) => state.isWritingScheduleDay,
    isFetchingMonthlyScheduleDays: (state) => state.isFetchingMonthlyScheduleDays,
    studentScheduleDay: (state) => (studentId, date, month, year) => {
      const compositeId = `${studentId}-${date}-${month}-${year}`;
      return state.studentScheduleDays[compositeId];
    },
    scheduleDiagramContent: (state) => state.scheduleDiagramContent
  },
  mutations: {
    setUnsubscribeFunctions (state, functions) {
      state.firestoreUnsubscribeFunctions = functions;
    },
    studentsNextPage (state) {
      if (!state.searchResult) {
        return;
      }
      if (state.searchPageNumber + 1 < state.searchResult.nbPages) {
        state.searchPageNumber++;
      }
    },
    studentsPrevPage (state) {
      if (!state.searchResult) {
        return;
      }
      if (state.searchPageNumber > 0) {
        state.searchPageNumber--;
      }
    },
    studentsSetPage (state, page) {
      state.searchPageNumber = page;
    },  
    storeStudent (state, record) {
      record.data.id = record.id;
      Vue.set(state.students, record.id, record.data)
    },
    storeGroup (state, record) {
      record.data.id = record.id;
      Vue.set(state.groups, record.id, record.data);
    },
    setSearchQueryText (state, value) {
      Vue.set(state.searchQuery, 'text', value);
    },
    storeScheduleDay (state, record) {
      record.data.id = record.id;
      Vue.set(state.scheduleDays, record.id, record.data);

      let studentId = null;
      if (typeof record.data.student === 'object') {
        studentId = record.data.student.id;
      } else {
        studentId = record.data.student;
      }
      const compositeId = `${studentId}-${record.data.date}-${record.data.month}-${record.data.year}`;
      Vue.set(state.studentScheduleDays, compositeId, record.data);

      // console.log(`Storing ${compositeId}`);
    }
  },
  actions: {
    async login(____, { email, password }) {
      await auth().signInWithEmailAndPassword(email, password);
    },
    async logout({ state }) {
      state.firestoreUnsubscribeFunctions.forEach(f => f());
      state.firestoreUnsubscribeFunctions = [];
      await auth().signOut();
    },
    async initAlgolia({ state }) {
      const token = await auth().currentUser.getIdToken();
      const projectId = firebaseConfig.projectId;
      const response = await axios.get(`https://europe-west3-${projectId}.cloudfunctions.net/getSearchKey/`, {
          headers: { Authorization: 'Bearer ' + token }
      });
      state.algolia = new Algolia(process.env.VUE_APP_ALGOLIA_APP_ID, response.data.key);
    },
    async createStudent({ dispatch }, data) {
      if (typeof data.group === 'string') {
        data.group = db.groups.doc(data.group);
      }
      const student = await db.students.add(data);
      await dispatch('searchStudents');
      return dispatch('fetchStudent', student.id);
    },
    async updateStudent({ dispatch }, student) {
      const data = {...student};
      if (typeof data.group === 'string') {
        data.group = db.groups.doc(data.group);
      }
      delete data.id;
      await db.students.doc(student.id).update(data);
      return dispatch('fetchStudent', student.id);
    },
    async fetchStudent({ commit, getters }, studentId) {
      const student = await db.students.doc(studentId).get();
      commit('storeStudent', { id: student.id, data: student.data() });
      return getters.student(student.id);
    },
    async fetchStudents({ commit, getters }, studentIds) {
      if (!Array.isArray(studentIds)) {
        throw new Error('Student IDs is not an array');
      }
      let idBatches = batchifyArray(studentIds, 10);
      const result = await Promise.all(idBatches.map(ids => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
          try {
            const students = await db.students.where(
              Firebase.firestore.FieldPath.documentId(), 'in', ids
            ).get();
            const refs = [];
            students.forEach(student => {
              commit('storeStudent', { id: student.id, data: student.data() });
              refs.push(getters.student(student.id))
            });
            resolve(refs);
          } catch (error) {
            reject(error);
          }
        });
      }));
      return result.flat();
    },
    async fetchScheduleDay({ getters, commit }, scheduleDayId) {
      const scheduleDay = await db.scheduleDays.doc(scheduleDayId).get();
      commit('storeScheduleDay', { id: scheduleDay.id, data: scheduleDay.data() });
      return getters.scheduleDays(scheduleDay.id);
    },
    async searchStudents({ state, getters, commit }) {
      state.isLoadingSearchResults = true;
      try {
        const index = state.algolia.initIndex('Students');
        state.searchResult = await index.search(state.searchQuery.text, {
          page: state.searchPageNumber
        });
        state.searchTotalFound = state.searchResult.nbHits;
        const ids = getters.searchFoundStudentIds;
        if (ids.length > 0) {
          const foundStudents = await db.students.where(
            Firebase.firestore.FieldPath.documentId(), 'in', ids
          ).get();
          foundStudents.forEach(student => {
            commit('storeStudent', { id: student.id, data: student.data() });
          });
        }
        state.isLoadingSearchResults = false;
      } catch (error) {
        state.isLoadingSearchResults = false;
        throw error;
      }
    },
    async searchStudentsNextPage({ dispatch, commit }) {
      commit('studentsNextPage');
      return dispatch('searchStudents');
    },
    async searchStudentsPrevPage({ dispatch, commit }) {
      commit('studentsPrevPage');
      return dispatch('searchStudents');
    },
    async fetchStudentScheduleDayForMonth({ getters, dispatch, commit, state }, { month, year, studentId }) {
      state.isFetchingMonthlyScheduleDays = true;
      try {
        const student = await dispatch('fetchStudent', studentId);
        const existingRecords = await db.scheduleDays
          .where('student', '==', db.students.doc(studentId))
          .where('timezone', '==', student.timezone)
          .where('month', '==', month)
          .where('year', '==', year).get();
        if (!existingRecords.empty) {
          const ids = existingRecords.docs.map((doc) => {
            commit('storeScheduleDay', { id: doc.id, data: doc.data() });
            return doc.id;
          });
          state.isFetchingMonthlyScheduleDays = false;
          return ids.map(id => getters.scheduleDays(id));
        } else {
          state.isFetchingMonthlyScheduleDays = false;
          return [];
        }
      } catch (error) {
        state.isFetchingMonthlyScheduleDays = false;
        return [];
      }
    },
    async fetchStudentScheduleDay({ getters, commit }, { date, month, year, student }) {
      const existingRecords = await db.scheduleDays
        .where('student', '==', db.students.doc(student))
        .where('timezone', '==', getters.student(student).timezone)
        .where('date', '==', date)
        .where('month', '==', month)
        .where('year', '==', year).get();
      if (!existingRecords.empty) {
        const record = existingRecords.docs[0];
        commit('storeScheduleDay', { id: record.id, data: record.data() });
        return getters.scheduleDays[record.id];
      } else {
        return [];
      }
    },
    async fetchScheduleDaysOfStudentsInRange({ commit, getters }, { studentIds, from, to }) {
      const batches = batchifyArray(studentIds, 10);
      const result = await Promise.all(batches.map((studentIdBatch) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
          try {
            const existingRecords = await db.scheduleDays
              .where('student', 'in', studentIdBatch.map(id => db.students.doc(id)))
              .where('timestamp', '>=', from)
              .where('timestamp', '<=', to).get();
            if (!existingRecords.empty) {
              const ids = existingRecords.docs.map((doc) => {
                commit('storeScheduleDay', { id: doc.id, data: doc.data() });
                return doc.id;
              });
              return resolve(ids.map(id => getters.scheduleDays(id)));
            } else {
              return resolve([]);
            }
          } catch (error) {
            return reject(error);
          }
        });
      }));
      return result.flat();
    },
    async ensureGroupByName({ getters, commit }, name) {
      const group = await db.groups.where('name', '==', name).get();
      if (group.empty) {
        const newGroup = await db.groups.add({ name });
        const createdGroup = await db.groups.doc(newGroup.id).get();
        commit('storeGroup', {
          id: createdGroup.id,
          data: createdGroup.data()
        });
        return getters.group(createdGroup.id);
      } else {
        const id = group.docs[0].id;
        commit('storeGroup', {
          id, data: group.docs[0].data()
        });
        return getters.group(id);
      }
    },
    async fetchGroups({ state, commit }) {
      state.isFetchingGroups = true;
      try {
        const groups = await db.groups.get();
        groups.forEach((g) => {
          commit('storeGroup', {
            id: g.id,
            data: g.data()
          });
        });
        state.isFetchingGroups = false;
      } catch (error) {
        state.isFetchingGroups = false;
        throw error;
      }
    },
    async fetchStudentIdsOfGroup({ dispatch, state }, groupId) {
      const students = await db.students.where('group', '==', db.groups.doc(groupId)).get();
      if (students.empty) {
        return [];
      }
      const ids = [];
      students.forEach((s) => {
        ids.push(s.id);
      });
      return ids;
    },  
    async prepareScheduleDiagramContent({ dispatch, state }, { studentIds, from, to, timezone }) {
      let students = await dispatch('fetchStudents', studentIds);
      const scheduleDays = await dispatch('fetchScheduleDaysOfStudentsInRange', {
        studentIds: students.map((s) => s.id),
        from, to
      });
      students.forEach(student => {
        const studentScheduleDays = scheduleDays.filter(d => d.student.id === student.id);
        student.timeline = studentScheduleDays.map(scheduleDay => {
          const dayStart = dayjs.tz(`${scheduleDay.year}-${scheduleDay.month}-${scheduleDay.date}`, 'utc' || scheduleDay.timezone);
          return (scheduleDay.workingHours || []).map(workingHours => {
            let to = workingHours.to;
            if (workingHours.to <= workingHours.from) {
              to += 24 * 60 * 60 * 1000
            }
            return {
              from: dayStart.add(workingHours.from, 'milliseconds').unix() * 1000,
              to: dayStart.add(to, 'milliseconds').unix() * 1000,
              type: 'work'
            }
          });
        });
        student.timeline = student.timeline.flat();
        student.timeline = compileTimeline(student.timeline);
        student.dates = {};
        student.timeline.forEach((stamp) => {
          const dateStr = dayjs(stamp.from).tz(timezone).format('YYYY-MM-DD');
          if (!student.dates[dateStr]) {
            student.dates[dateStr] = [];
          }
          student.dates[dateStr].push(stamp);
        });
      });
      state.scheduleDiagramContent = students;
      return students;
    },
    async setStudentScheduleDay({ dispatch, state }, scheduleDay) {
      state.isWritingScheduleDay = true;
      try {
        if (!scheduleDay.student) {
          throw new Error('No student specified');
        }
        if (typeof scheduleDay.student === 'string') {
          scheduleDay.student = db.students.doc(scheduleDay.student);
        }
        if (scheduleDay.id) {
          const data = {...scheduleDay};
          delete data.id;
          await db.scheduleDays.doc(scheduleDay.id).update(data);
        } else {
          // Find existing record
          const existingRecords = await db.scheduleDays
            .where('student', '==', scheduleDay.student)
            .where('date', '==', scheduleDay.date)
            .where('month', '==', scheduleDay.month)
            .where('year', '==', scheduleDay.year).get();
          if (!existingRecords.empty) {
            await db.scheduleDays.doc(existingRecords.docs[0].id).update(scheduleDay);
            scheduleDay.id = existingRecords.docs[0].id;
          } else {
            scheduleDay.id = (await db.scheduleDays.add(scheduleDay)).id;
          }
        }
        await dispatch('fetchScheduleDay', scheduleDay.id);
      } catch (error) {
        state.isWritingScheduleDay = false;
        throw error;
      }
      state.isWritingScheduleDay = false;
    }
  }
});