import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, alpha_spaces } from 'vee-validate/dist/rules';
import Vue from 'vue';

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('email', {
  ...email,
  message: 'This field has to be a valid email'
});

extend('alpha_spaces', {
  ...alpha_spaces,
  message: 'This field can only contain alphabetic characters and spaces'
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);