
export default function batchifyArray(arr, batchSize) {
  let batches = [];
  if (arr.length <= batchSize) {
    return [arr];
  } else {
    arr = [...arr];
    const count = arr.length;
    for (let i = 0; i < count / batchSize; i++) {
      batches.push(arr.splice(0, batchSize));
    }
  }
  return batches;
}