<template>
  <div id="layout">
    <b-navbar class="navbar" toggleable="lg" type="light" variant="white">
      <b-navbar-brand href="#">Student Monitoring</b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <div class="mr-4 mt-2">
          <div v-for="link in links" :key="link.toName" class="d-inline">
            <router-link v-if="link.isActive" :to="{ name: link.toName }" class="mr-3">{{link.label}}</router-link>
            <span v-else class="mr-3">{{link.label}}</span>
          </div>
        </div>
        <b-button class="float-right d-inline" @click="logout">Logout</b-button>
      </b-navbar-nav>
    </b-navbar>
    <div class="content">
      <b-row class="justify-content-center">
        <b-col cols="11" lg="11">
          <!--<div>
            <div v-for="(link, index) in links" :key="link.toName" class="d-inline">
              <router-link v-if="link.isActive" :to="{ name: link.toName }">{{link.label}}</router-link>
              <span v-else>{{link.label}}</span>
              <span v-if="index !== links.length - 1">&nbsp;/&nbsp;</span>
            </div>
          </div>-->
          <div class="workspace">
            <router-view/>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'main-layout',
  computed: {
    links() {
      const routeName = this.routeName;
      return ['Students', 'Schedules'].map(l => {
        return {
          toName: l,
          label: l,
          isActive: routeName !== l
        }
      });
    },
    routeName() {
      return this.$route.name;
    }
  },
  methods: {
    async logout() {
      await this.$store.dispatch('logout');
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>
<style lang="scss">
.content {
  margin-top: 20px;
}
.navbar {
  border-bottom-width: 1px;
  border-bottom-color: rgb(0, 0, 0);
  border-bottom-style: solid;
}
.workspace {
  min-height: 400px;
}
</style>