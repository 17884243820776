<template>
  <div id="layout">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'login-layout',
  computed: {
    routeName() {
      return this.$route.name;
    }
  }
}
</script>
<style lang="scss">
</style>