import Vue from 'vue'
// import VueFirestore from 'vue-firestore'
import Firebase from 'firebase/app'

require('firebase/auth')
require('firebase/firestore')
require('firebase/functions')
 
// Vue.use(VueFirestore)

const config = {
  apiKey: "AIzaSyDnSp8F4tjdH5mZqqxD7Y7iUNIWLqBQ2Fs",
  authDomain: "student-schedules-6383a.firebaseapp.com",
  databaseURL: "https://student-schedules-6383a-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "student-schedules-6383a",
  storageBucket: "student-schedules-6383a.appspot.com",
  messagingSenderId: "132609649013",
  appId: "1:132609649013:web:a3e930822152493d4e8b3c",
  measurementId: "G-JJQ9DSTK76"
};

const firebaseApp = Firebase.initializeApp(config);
const firestore = firebaseApp.firestore();
Vue.prototype.$firestore = firestore;
Vue.prototype.$firebase = firebaseApp;

export const firestoreInstance = firestore;

export const firestoreCollections = () => {
  return {
    students: firestore.collection('students'),
    scheduleDays: firestore.collection('schedule-days'),
    groups: firestore.collection('groups')
  }
};

export const auth = () => {
  return firebaseApp.auth();
};

export const functions = () => {
  return firebaseApp.functions('europe-west3')
};

export const firebaseConfig = config;