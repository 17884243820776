import Vue from 'vue'
import VueRouter from 'vue-router'
import navGuard from '../navGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Students',
    meta: { layout: 'main-layout', authRequired: true },
    component: () => import(/* webpackChunkName: "students" */ '../views/Students.vue')
  },
  {
    path: '/schedules',
    name: 'Schedules',
    meta: { layout: 'main-layout', authRequired: true },
    component: () => import(/* webpackChunkName: "schedules" */ '../views/Schedules.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'login-layout' },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(navGuard);

export default router
