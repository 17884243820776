
export default function compileTimeline(timeline) {
  timeline = [...timeline];
  //const result = [];
  timeline = timeline.sort((a, b) => {
    return a.from - b.from;
  });
  for (let i = 0; i < timeline.length - 1; i++) {
    const a = timeline[i];
    const b = timeline[i+1];
    if (Math.abs(a.to - b.from) < 5 * 60000) {
      timeline.splice(i+1, 1);
      i--;
      a.to = b.to;
    }
  }
  return timeline;
}