import { auth, firestoreCollections } from './firebase';
import store from './store/store';

function subscribeToRealtimeUpdates() {
  const db = firestoreCollections();
  const unsubscribe = [];
  unsubscribe.push(db.students.onSnapshot((function (snap) {
    if (snap.docChanges) {
      for (const change of snap.docChanges()) {
        if (['added', 'modified'].includes(change.type)) {
          store.commit('storeStudent', {
            id: change.doc.id,
            data: change.doc.data()
          });
        }
      }
    }
  }).bind(this)));

  unsubscribe.push(db.scheduleDays.onSnapshot((function (snap) {
    if (snap.docChanges) {
      for (const change of snap.docChanges()) {
        if (['added', 'modified'].includes(change.type)) {
          store.commit('storeScheduleDay', {
            id: change.doc.id,
            data: change.doc.data()
          });
        }
      }
    }
  }).bind(this)));

  store.commit('setUnsubscribeFunctions', unsubscribe); 
  console.log('Subscribed');
}

export default async (to, from, next) => {
  if (to.name === 'Login' || to.matched.some(record => record.meta.authRequired)) {
    await new Promise((resolve, reject) => {
      auth().onAuthStateChanged(function(user) {
        resolve(user);
      });
      setTimeout(reject, 5000);
    });
    console.log('Auth finished');
    if (auth().currentUser) {
      if (to.name === 'Login') {
        next({ path: '/' });
      } else {
        subscribeToRealtimeUpdates();
        await store.dispatch('initAlgolia');
        next();
      }
    } else {
      if (to.name === 'Login') {
        next();
      } else {
        next({
          name: 'Login'
        });
      }
    }
  } else {
    next();
  }
}