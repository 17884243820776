<template>
  <div id="app">
    <component :is="layout" />
  </div>
</template>
<script>
import MainLayout from '@/layouts/Main';
import LoginLayout from '@/layouts/Login';
export default {
  components: {
    MainLayout,
    LoginLayout
  },
  computed: {
    layout: {
      get () {
        return this.$route.meta.layout;
      }
    }
  }
}
</script>
